import Script from "next/script";
import "../styles/globals.css";
import dynamic from "next/dynamic";
import { ApolloProvider } from "@apollo/client";
import { AppProps } from "next/app";
import { useApollo } from "../lib/apolloClient";
import { useRouter } from "next/router";
import { useEffect, useRef } from "react";
import { i18n, Messages } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { initTranslation, loadTranslation } from "@lib/utils";
import { DefaultSeo } from "next-seo";
import { ToastContainer } from "react-toastify";
import { t } from "@lingui/macro";
import { MainBannerContext } from "@lib/mainBannerContext";
import FeedbackProvider from "hooks/feedbackContext";
import { ErrorBoundary } from "@sentry/nextjs";

const DownloadAppBanner = dynamic(
  () => import("@components/download-app-banner")
);
const AppDialog = dynamic(() => import("@components/core/dialog"));

initTranslation(i18n);

type PageProps = {
  translation?: Messages;
  initialApolloState?: Object;
};

function MyApp({
  Component,
  pageProps = {},
  err,
}: AppProps<PageProps> & { err: any }) {
  const apolloClient = useApollo(pageProps.initialApolloState);
  const router = useRouter();
  const locale = router.locale || router.defaultLocale;
  const firstRender = useRef(true);
  // to show main banner if there is a new campaign or notice which takes care of all the cosmetics
  const showMainBanner = false;
  const propsToDisableIndexingIfNotProduction = {};
  // process.env.DOMAIN_NAME != "https://luminews.my"
  //   ? {
  //       dangerouslySetAllPagesToNoIndex: true,
  //       dangerouslySetAllPagesToNoFollow: true,
  //     }
  //   : {};

  // run only once on the first render (for server side)
  if (firstRender.current) {
    if (pageProps.translation) {
      i18n.load(locale, pageProps.translation);
      i18n.activate(locale);
    } else {
      loadTranslation(locale, true).then((translations) => {
        i18n.load(locale, translations);
        i18n.activate(locale);
      });
    }
    firstRender.current = false;
  }

  // listen for the locale changes
  useEffect(() => {
    // Commented out for now, will come back to this if the web needs to reset cache
    // if (!firstRender.current) {
    //   apolloClient.resetStore();
    // }

    if (pageProps.translation) {
      i18n.load(locale, pageProps.translation);
      i18n.activate(locale);
    }
  }, [locale, pageProps.translation]);

  useEffect(() => {
    const handleRouteChangeError = (err, url) => {
      console.log({ err, url });
      if (err.cancelled) {
        console.log(`Route to ${url} was cancelled!`);
      }
    };

    router.events.on("routeChangeError", handleRouteChangeError);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off("routeChangeError", handleRouteChangeError);
    };
  }, []);

  const showNoBannerRoutes = [
    "/about-us",
    "/careers",
    "/careers/[name]",
    "/news",
    "/news/[id]",
    "/news-stack/[name]",
    "/faq",
    "/prn-2023",
  ];

  const metaImages = {
    en: "https://luminews.my/en-seoimage.png",
    ms: "https://luminews.my/ms-seoimage.png",
    zh: "https://luminews.my/zh-seoimage.png",
  };

  const seoLocale = "/" + locale;

  const title = t`Lumi News Malaysia`;
  const description = t`Download the Lumi app to stay updated, get notified when something trends, select topics you want to follow, and share news quickly.`;

  return (
    <ErrorBoundary>
      <ApolloProvider client={apolloClient}>
        <Script id="gtag" strategy="afterInteractive">
          {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-PK8VDFL');
        `}
        </Script>
        <Script id="lotame" strategy="afterInteractive">
          {`! function() {
          const lotameClientId = '${process.env.LOTAME_CLIENT_ID}';
          var lotameTagInput = {
          data: {},
          config: {
          clientId: Number(lotameClientId)
          }
          };
          // Lotame initialization
          var lotameConfig = lotameTagInput.config || {};
          var namespace = window['lotame_' + lotameConfig.clientId] = {};
          namespace.config = lotameConfig;
          namespace.data = lotameTagInput.data || {};
          namespace.cmd = namespace.cmd || [];
          } ();`}
        </Script>
        <Script
          src={`https://tags.crwdcntrl.net/lt/c/${process.env.LOTAME_CLIENT_ID}/lt.min.js`}
          async
        ></Script>
        <Script id="smart-banner-pba" strategy="afterInteractive">
          {`!function(t,e,n,s,a,c,i,o,p){t.AppsFlyerSdkObject=a,t.AF=t.AF||function(){
          (t.AF.q=t.AF.q||[]).push([Date.now()].concat(Array.prototype.slice.call(arguments)))},
          t.AF.id=t.AF.id||i,t.AF.plugins={},o=e.createElement(n),p=e.getElementsByTagName(n)[0],o.async=1,
          o.src="https://websdk.appsflyer.com?"+(c.length>0?"st="+c.split(",").sort().join(",")+"&":"")+(i.length>0?"af_id="+i:""),
          p.parentNode.insertBefore(o,p)}(window,document,"script",0,"AF", "pba,banners",{pba: {webAppId: "${process.env.APPSFLYER_DEV_KEY}"}, banners: {key: "${process.env.SMART_BANNER_WEB_KEY}"}});
          // Smart Banners are by default set to the max z-index value, so they won't be hidden by the website elements. This can be changed if you want some website components to be on top of the banner.
          AF('banners', 'showBanner', { bannerZIndex: 1000, additionalParams: { p1: "v1", p2: "v2"}});`}
        </Script>
        <DefaultSeo
          {...propsToDisableIndexingIfNotProduction}
          title={title}
          description={description}
          openGraph={{
            url: `https://luminews.my${
              seoLocale.includes("en") ? "" : seoLocale
            }${router.asPath}`,
            site_name: "Lumi News Malaysia",
            type: "website",
            title: title,
            description: description,
            images: [
              {
                url: `${metaImages[locale]}`,
                alt: "Lumi News Malaysia",
              },
            ],
          }}
          facebook={{
            appId: "1194404434408598",
          }}
          twitter={{
            site: "@luminewsmy",
            cardType: "summary_large_image",
          }}
          additionalMetaTags={[
            {
              property: "al:web:url",
              content: "luminews.my" + router.asPath + "/",
            },
            {
              property: "al:ios:url",
              content: "luminews://luminews.my" + router.asPath + "/",
            },
            {
              property: "al:ios:app_store_id",
              content: "1569349004",
            },
            {
              property: "al:ios:app_name",
              content: "Lumi News Malaysia",
            },
            {
              property: "al:android:url",
              content: "luminews://luminews.my" + router.asPath + "/",
            },
            {
              property: "al:android:package",
              content: "global.lumi.lumi",
            },
            {
              property: "al:android:app_name",
              content: "Lumi News Malaysia",
            },
          ]}
        />
        <I18nProvider i18n={i18n}>
          <FeedbackProvider>
            <MainBannerContext.Provider value={showMainBanner}>
              <Component {...pageProps} err={err} />
              <ToastContainer
                position="bottom-left"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
              {/* IMPLEMENTED APPSFLYER SMART BANNER SO HIDE HOMEPAGE AND /NEWS PAGE FOR NOW */}
              {!showNoBannerRoutes.find((route) =>
                router.pathname.includes(route)
              ) &&
                router.asPath !== "/" && <DownloadAppBanner />}
              <AppDialog />
            </MainBannerContext.Provider>
          </FeedbackProvider>
        </I18nProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
}

export default MyApp;
